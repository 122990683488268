import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { theme } from "@chakra-ui/pro-theme";
import { colors } from "./theme/colors";
import { fonts } from "./theme/fonts";
import "@fontsource/poppins";
import "@fontsource/biryani";
import { AuthProvider } from "./contexts/auth";
import Routes from "./routes/Routes";
import { isTodayBefore } from "./utils/helpers";

const isBlackFriday = isTodayBefore("2022-11-26");

const orbitTheme = extendTheme(
  {
    initialColorMode: isBlackFriday ? "dark" : "light",
    useSystemColorMode: !isBlackFriday,
    fonts: fonts,
    colors: colors,
  },
  theme
);

export const App = () => {
  return (
    <ChakraProvider theme={orbitTheme}>
      <AuthProvider>
        <Routes></Routes>
      </AuthProvider>
    </ChakraProvider>
  );
};
