import { useQuery } from "@apollo/client";
import { Text } from "@chakra-ui/react";
import { Navigate, useParams } from "react-router-dom";
import FullScreenLoader from "../../components/FullScreenLoader";
import { GET_VSL_BY_ID } from "../../graphql/queries";
import { Mixpanel } from "../../utils/mixpanel";
import PPTXGen from "../../utils/pptxgen";

const RegenerateVSL = () => {
  const { vslId } = useParams();
  const { loading, error, data } = useQuery(GET_VSL_BY_ID, {
    variables: { vsl_id: vslId },
  });

  if (loading) return <FullScreenLoader />;
  if (error) return <Text>Erro! {error.message}</Text>;

  const vsl = data.vsls_by_pk;
  const vslText = JSON.parse(vsl.editor_content);

  const pptx = new PPTXGen({
    vslText: vslText?.blocks,
    slideLayout: vsl.layout,
    fontSize: vsl.font_size,
    textColor: vsl.text_color,
    bgColor: vsl.bg_color,
    fontFamily: vsl.font_family,
    markupTextColor: vsl.markup_text_color,
    markupBgColor: vsl.markup_bg_color,
    bgImage: vsl.bg_image,
    verticalAlign: vsl.vertical_align,
    margin: vsl.margin,
    textPerSlide: vsl.text_per_slide,
    vslTitle: vsl.title,
    ucFirst: vsl.uc_first,
    ucWords: vsl.uc_words,
    endWithEllipsis: vsl.end_with_ellipsis,
    isPreview: false,
    isFreeCredit: vsl.transaction.package.item_type === "FREE" ? true : false,
  });

  pptx.generatePPTX();

  Mixpanel.track(`Regenerated PPTX`, {
    vsl_id: vslId,
    credit_type: vsl.transaction.package.item_type,
  });

  return <Navigate to="/dashboard" replace={true} />;
};

export default RegenerateVSL;
