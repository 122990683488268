import { Text } from "@chakra-ui/react";

/**
 * Retorna o objeto com um value específico de dentro de um array.
 *
 * @param options
 * @param selected
 * @returns
 */
export const getSelectedOption = (
  options: Array<{ value: string; label: any }>,
  selected: string
) => options.find(({ value }) => value === selected);

/**
 * Fontes
 */
export const fontOptions: any = [
  {
    value: "Arial",
    label: <Text style={{ fontFamily: "Arial" }}>Arial</Text>,
  },
  {
    value: "Biryani",
    label: <Text style={{ fontFamily: "Biryani" }}>Byriani</Text>,
  },
  {
    value: "Calibri",
    label: <Text style={{ fontFamily: "Calibri" }}>Calibri</Text>,
  },
  {
    value: "Comic Sans MS",
    label: <Text style={{ fontFamily: "Comic Sans MS" }}>Comic Sans</Text>,
  },
  {
    value: "Courier New",
    label: <Text style={{ fontFamily: "Courier New" }}>Courier New</Text>,
  },
  {
    value: "Helvetica Neue",
    label: <Text style={{ fontFamily: "Helvetica Neue" }}>Helvetica Neue</Text>,
  },
  {
    value: "Impact",
    label: <Text style={{ fontFamily: "Impact" }}>Impact</Text>,
  },
  {
    value: "Lato",
    label: <Text style={{ fontFamily: "Lato" }}>Impact</Text>,
  },
  {
    value: "Montserrat",
    label: <Text style={{ fontFamily: "Montserrat" }}>Montserrat</Text>,
  },
  {
    value: "Open Sans",
    label: <Text style={{ fontFamily: "Open Sans" }}>Open Sans</Text>,
  },
  {
    value: "Oswald",
    label: <Text style={{ fontFamily: "Oswald" }}>Oswald</Text>,
  },
  {
    value: "Poppins",
    label: <Text style={{ fontFamily: "Poppins" }}>Poppins</Text>,
  },
  {
    value: "Roboto",
    label: <Text style={{ fontFamily: "Roboto" }}>Roboto</Text>,
  },
  {
    value: "Special Elite",
    label: <Text style={{ fontFamily: "Special Elite" }}>Special Elite</Text>,
  },
  {
    value: "Tahoma",
    label: <Text style={{ fontFamily: "Tahoma" }}>Tahoma</Text>,
  },
  {
    value: "Times New Roman",
    label: (
      <Text style={{ fontFamily: "Times New Roman" }}>Times New Roman</Text>
    ),
  },
  {
    value: "Ubuntu",
    label: <Text style={{ fontFamily: "Ubuntu" }}>Ubuntu</Text>,
  },
  {
    value: "Verdana",
    label: <Text style={{ fontFamily: "Verdana" }}>Verdana</Text>,
  },
];

/**
 * Opções de layout
 */
export const layoutOptions: any = [
  {
    value: "LAYOUT_16x9",
    label: <Text>16:9</Text>,
  },
  {
    value: "LAYOUT_16x10",
    label: <Text>16:10</Text>,
  },
  {
    value: "LAYOUT_4x3",
    label: <Text>4:3</Text>,
  },
  {
    value: "LAYOUT_WIDE",
    label: <Text>Widescreen</Text>,
  },
  {
    value: "LAYOUT_SQUARE",
    label: <Text>Quadrado</Text>,
  },
];

/**
 * Alinhamento vertical
 */
export const verticalAlignOptions: any = [
  {
    value: "TOP",
    label: <Text>Topo</Text>,
  },
  {
    value: "MIDDLE",
    label: <Text>Meio</Text>,
  },
  {
    value: "BOTTOM",
    label: <Text>Rodapé</Text>,
  },
];

/**
 * Margens
 */
export const marginOptions: any = [
  {
    value: "SMALL",
    label: <Text>Pequenas</Text>,
  },
  {
    value: "MEDIUM",
    label: <Text>Médias</Text>,
  },
  {
    value: "LARGE",
    label: <Text>Grandes</Text>,
  },
];

/**
 * Texto por slide
 */
export const textPerSlideOptions: any = [
  {
    value: "DEFAULT",
    label: <Text>Original</Text>,
  },
  {
    value: "LITTLE",
    label: (
      <Text>
        Pouco <small>(1 a 2 linhas)</small>
      </Text>
    ),
  },
  {
    value: "MEDIUM",
    label: (
      <Text>
        Médio <small>(2 a 3 linhas)</small>
      </Text>
    ),
  },
  {
    value: "LARGE",
    label: (
      <Text>
        Muito <small>(3 a 5 linhas)</small>
      </Text>
    ),
  },
];
