import { CircularProgress, Stack, Text } from "@chakra-ui/react";
import { useEffect } from "react";
import { useAuth } from "../../contexts/auth";
import { getFirstPath } from "../../utils/helpers";
import { Mixpanel } from "../../utils/mixpanel";

const HelpPage = () => {
  const { user } = useAuth();

  useEffect(() => {
    Mixpanel.track(`Clicked help`, {
      from_path: getFirstPath(),
    });

    window.open(
      `https://wa.me/5581996079045?text=Ol%C3%A1%21%20Preciso%20de%20ajuda%20com%20o%20Gerador%20de%20VSL.%20Meu%20e-mail%20%C3%A9%3A%20${user?.email}.`
    );
  }, [user?.email]);

  return (
    <Stack
      width="full"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        isIndeterminate
        color="teal.400"
        size="75px"
        thickness="10px"
      />
      <Text>Uma nova aba de conversa no WhatsApp foi aberta.</Text>
    </Stack>
  );
};

export default HelpPage;
