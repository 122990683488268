const defaultEditorJSData = {
  time: 1664242353575,
  blocks: [
    {
      id: "o-zvg_blJy",
      type: "paragraph",
      data: {
        text: "<b>Gerador de VSL:</b> <i>1 Parágrafo = 1 Slide. Simples assim.</i>",
      },
    },
    {
      id: "zIa8JnGwEo",
      type: "paragraph",
      data: {
        text: "Criar os slides da sua VSL é <b>SIMPLES</b>, e <b>RÁPIDO</b>.",
      },
    },
    {
      id: "V9-1WLWb3a",
      type: "paragraph",
      data: {
        text: "Cada parágrafo informado aqui gera um único slide Power Point.",
      },
    },
    {
      id: "cWbjW-I0VI",
      type: "paragraph",
      data: {
        text: 'Use <b>negrito</b>,&nbsp;<i>itálico,&nbsp;</i>aplique&nbsp;<mark class="cdx-marker">destaques</mark>,&nbsp;<u class="cdx-underline">sublinhado</u>, e use até tudo&nbsp;<b><i><u class="cdx-underline"><mark class="cdx-marker">junto</mark></u></i></b>&nbsp;para dar ÊNFASES.',
      },
    },
    {
      id: "1VWpSQ2fHe",
      type: "paragraph",
      data: {
        text: 'Customize fonte, cores, <u class="cdx-underline">imagem de fundo</u> e formato dos slides.',
      },
    },
    {
      id: "rlG7Ct2M4R",
      type: "paragraph",
      data: {
        text: 'Clique no botão "Gerar VSL" e BOAS VENDAS! ',
      },
    },
    {
      id: "RoHUKsTY0X",
      type: "paragraph",
      data: {
        text: "Abraço e <b>SUCESSO!</b><br>Time Orbit.com.vc",
      },
    },
    {
      id: "_20bh3hVSk",
      type: "paragraph",
      data: {
        text: "<b>PS:</b> Não tem Power Point nem Libre Office instalado? Sem problema!",
      },
    },
    {
      id: "qamtbgKiWp",
      type: "paragraph",
      data: {
        text: "Faça upload para o Google Drive e edite sua VSL no Google Slides.",
      },
    },
  ],
  version: "2.25.0",
};

export default defaultEditorJSData;
