import { theme as baseTheme } from "@chakra-ui/react";

/**
 * @see https://react-select.com/styles#overriding-the-theme
 */
export const reactSelectThemeLight: any = {
  primary: "#12beb1",
  primary75: baseTheme.colors.gray[100],
  primary50: baseTheme.colors.gray[100],
  primary25: baseTheme.colors.gray[100],
  danger: baseTheme.colors.red,
  dangerLight: baseTheme.colors.red[100],
  neutral0: "#ffffff",
  neutral5: baseTheme.colors.gray[50],
  neutral10: baseTheme.colors.gray[100],
  neutral20: baseTheme.colors.gray[200],
  neutral30: baseTheme.colors.gray[300],
  neutral40: baseTheme.colors.gray[400],
  neutral50: baseTheme.colors.gray[500],
  neutral60: baseTheme.colors.gray[600],
  neutral70: baseTheme.colors.gray[700],
  neutral80: baseTheme.colors.gray[800],
  neutral90: baseTheme.colors.gray[900],
};

export const reactSelectThemeDark: any = {
  primary: "#12beb1",
  primary75: baseTheme.colors.gray[700],
  primary50: baseTheme.colors.gray[700],
  primary25: baseTheme.colors.gray[700],
  danger: baseTheme.colors.red,
  dangerLight: baseTheme.colors.red[100],
  neutral0: baseTheme.colors.gray[800],
  neutral5: baseTheme.colors.gray[900],
  neutral10: baseTheme.colors.gray[800],
  neutral20: baseTheme.colors.gray[700],
  neutral30: baseTheme.colors.gray[600],
  neutral40: baseTheme.colors.gray[500],
  neutral50: baseTheme.colors.gray[400],
  neutral60: baseTheme.colors.gray[300],
  neutral70: baseTheme.colors.gray[200],
  neutral80: baseTheme.colors.gray[100],
  neutral90: baseTheme.colors.gray[50],
};
