import { Outlet } from "react-router-dom";
import { Box, Container, Flex, useBreakpointValue } from "@chakra-ui/react";
import { Navbar } from "./Navbar";
import { Sidebar } from "./Sidebar";

const Shell = () => {
  const isDesktop = useBreakpointValue({ base: false, md: true });
  return (
    <Flex
      as="section"
      direction={{ base: "column", lg: "row" }}
      height="100vh"
      bg="bg-canvas"
      overflowY="auto"
    >
      {isDesktop ? <Sidebar /> : <Navbar />}
      <Box
        bg="bg-surface"
        pt={{ base: "0", lg: "3" }}
        flex="1"
        marginLeft={isDesktop ? "20rem" : "0"}
      >
        <Box
          bg="bg-canvas"
          borderTopLeftRadius={{ base: "none", lg: "2rem" }}
          height="full"
        >
          <Container py="8" height="full">
            <Outlet />
          </Container>
        </Box>
      </Box>
    </Flex>
  );
};

export default Shell;
