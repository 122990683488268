/**
 * Packages padrão
 */
export const packageOptions = [
    {
        id: 1,
        credits: 1,
        total_price: 19.90,
        credit_price: 19.90,
        buy_url: "https://sun.eduzz.com/1644222"
    },
    {
        id: 2,
        credits: 5,
        total_price: 45.00,
        credit_price: 45.00 / 5,
        buy_url: "https://sun.eduzz.com/1644223"
    },
    {
        id: 3,
        credits: 50,
        total_price: 299.00,
        credit_price: 299.00 / 50,
        buy_url: "https://sun.eduzz.com/1644232"
    },
    {
        id: 4,
        credits: 150,
        total_price: 449.00,
        credit_price: 449.00 / 150,
        buy_url: "https://sun.eduzz.com/1644234"
    },
    {
        id: 5,
        credits: 500,
        total_price: 999.00,
        credit_price: 999.00 / 500,
        buy_url: "https://sun.eduzz.com/1644236"
    },
];

/**
 * Packages Black Friday 2022
 */
export const packageOptionsBlackFriday = [
    {
        id: 1,
        base_credits: 1,
        credits: 2,
        total_price: 19.90,
        base_credit_price: 19.90 / 1,
        credit_price: 19.90 / 2,
        buy_url: "https://sun.eduzz.com/1690052"
    },
    {
        id: 2,
        base_credits: 5,
        credits: 10,
        total_price: 45.00,
        base_credit_price: 45.00 / 5,
        credit_price: 45.00 / 10,
        buy_url: "https://sun.eduzz.com/1690057"
    },
    {
        id: 3,
        base_credits: 50,
        credits: 100,
        total_price: 299.00,
        base_credit_price: 299.00 / 50,
        credit_price: 299.00 / 100,
        buy_url: "https://sun.eduzz.com/1690061"
    },
    {
        id: 4,
        base_credits: 150,
        credits: 300,
        total_price: 449.00,
        base_credit_price: 449.00 / 150,
        credit_price: 449.00 / 300,
        buy_url: "https://sun.eduzz.com/1690064"
    },
    {
        id: 5,
        base_credits: 500,
        credits: 1000,
        total_price: 999.00,
        base_credit_price: 999.00 / 500,
        credit_price: 999.00 / 1000,
        buy_url: "https://sun.eduzz.com/1690067"
    },
];