import { useAuth } from "../contexts/auth";
import AppRoutes from "./AppRoutes";
import LoginRoutes from "./LoginRoutes";

const Routes = () => {
  const { signedIn } = useAuth();
  return signedIn ? <AppRoutes /> : <LoginRoutes />;
};

export default Routes;
