import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Grid,
  GridItem,
  Stack,
  Text,
  useColorModeValue,
  useId,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useToast,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { HiCursorClick, HiOutlineEye } from "react-icons/hi";
import "./NewVslForm.css";
import { useFormik } from "formik";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import React, { useState, useEffect } from "react";
import PPTXGen from "../../utils/pptxgen";
import editorJSOptions from "./options/editorjs/options";
import {
  fontOptions,
  getSelectedOption,
  layoutOptions,
  marginOptions,
  textPerSlideOptions,
  verticalAlignOptions,
} from "./options/form-options";
import {
  reactSelectThemeLight,
  reactSelectThemeDark,
} from "./options/react-select-theme";
import defaultEditorJSData from "./options/editorjs/default";
import { fileAsBase64 } from "../../utils/helpers";

import ReactSelect from "react-select";

import "./NewVslPage.css";
import { useLazyQuery, useMutation } from "@apollo/client";
import { SAVE_VSL, UPDATE_VSL } from "../../graphql/mutations";
import { useAuth } from "../../contexts/auth";
import { GET_ACTIVE_TRANSACTION_AND_BALANCE } from "../../graphql/queries";
import { Link } from "react-router-dom";
import axios from "axios";
import { Mixpanel } from "../../utils/mixpanel";

/**
 * VslProperties Type
 */
type VslProperties = {
  slideLayout: string;
  fontSize: number;
  textColor: string;
  bgColor: string;
  fontFamily: string;
  markupTextColor: string;
  markupBgColor: string;
  bgImage: string;
  verticalAlign: string;
  margin: string;
  textPerSlide: string;
  vslTitle: string;
  ucWords: boolean;
  ucFirst: boolean;
  endWithEllipsis: boolean;
};

/**
 * NewVslFormState Type
 */
type NewVslFormState = {
  vslProperties: VslProperties;
  vslText: any[];
  credits: number;
  editor?: EditorJS;
  transactionId: string;
  vslId: string;
  isSending: boolean;
  isGeneratingPreview: boolean;
  isFreeCredit: boolean;
};

/**
 * NewVslFormProps Type
 */
type NewVslFormProps = {
  baseVsl?: any;
};

const NewVslForm = ({ baseVsl }: NewVslFormProps) => {
  /**
   * Dialog
   */
  const {
    isOpen,
    onOpen: onOpenDialogConfirmation,
    onClose: onCloseDialogConfirmation,
  } = useDisclosure();

  /**
   * Usuário do contexto
   */
  const { user } = useAuth();

  /**
   * EditorJS
   */
  const editorJSId = useId();

  /**
   * Estado base
   */
  const [state, setState] = useState<NewVslFormState>({} as NewVslFormState);

  /**
   * Definição dos valores padrão do formulário
   */
  const time = new Date().toLocaleDateString();

  let formikInitialValues: VslProperties = {
    slideLayout: baseVsl?.layout !== undefined ? baseVsl.layout : "LAYOUT_16x9",
    fontSize: baseVsl?.font_size !== undefined ? baseVsl.font_size : 42,
    textColor:
      baseVsl?.text_color !== undefined ? baseVsl.text_color : "#222222",
    bgColor: baseVsl?.bg_color !== undefined ? baseVsl.bg_color : "#ffffff",
    fontFamily:
      baseVsl?.font_family !== undefined ? baseVsl.font_family : "Open Sans",
    markupTextColor:
      baseVsl?.markup_text_color !== undefined
        ? baseVsl.markup_text_color
        : "#d43c3c",
    markupBgColor:
      baseVsl?.markup_bg_color !== undefined
        ? baseVsl.markup_bg_color
        : "#ffffff",
    bgImage: "",
    verticalAlign:
      baseVsl?.vertical_align !== undefined ? baseVsl.vertical_align : "MIDDLE",
    margin: baseVsl?.margin !== undefined ? baseVsl.margin : "SMALL",
    textPerSlide:
      baseVsl?.text_per_slide !== undefined
        ? baseVsl.text_per_slide
        : "DEFAULT",
    vslTitle:
      baseVsl?.title !== undefined
        ? `Cópia de ${baseVsl.title}`
        : `Minha VSL - ${time}`,
    ucWords: baseVsl?.uc_words !== undefined ? baseVsl.uc_words : false,
    ucFirst: baseVsl?.uc_first !== undefined ? baseVsl.uc_first : false,
    endWithEllipsis:
      baseVsl?.end_with_ellipsis !== undefined
        ? baseVsl.end_with_ellipsis
        : false,
  };

  /**
   * Instancia o Formik passando os valores iniciais e o método para
   * lidar com a submissão do formulário
   */
  const formik = useFormik({
    initialValues: formikInitialValues,
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  /**
   * Queries e ma=utations do Graphql
   */
  const [saveVSL] = useMutation(SAVE_VSL);
  const [updateVSL] = useMutation(UPDATE_VSL);
  const [getActiveTransactionAndBalance] = useLazyQuery(
    GET_ACTIVE_TRANSACTION_AND_BALANCE,
    {
      fetchPolicy: "network-only",
    }
  );

  /**
   * Toast
   */
  const toast = useToast();

  /**
   * Opção de cancelar no alerta
   */
  const cancelAlertRef = React.useRef<HTMLButtonElement>(null);

  /**
   * Customização do tema do componente de select
   */
  const reactSelectThemeColors = useColorModeValue(
    reactSelectThemeLight,
    reactSelectThemeDark
  );

  /**
   * Usando useEffect para carregar o editorjs para resolver bug
   * de múltiplas instâncias sendo geradas
   */
  useEffect(() => {
    setState({
      vslText: [],
      vslProperties: {} as VslProperties,
      credits: 0,
      editor: new EditorJS({
        holder: editorJSId,
        data:
          baseVsl?.editor_content !== undefined
            ? JSON.parse(baseVsl.editor_content)
            : defaultEditorJSData,
        ...editorJSOptions,
      }),
      transactionId: "",
      vslId: "",
      isSending: false,
      isGeneratingPreview: false,
      isFreeCredit: false,
    });

    return () => {
      state.editor?.destroy();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Salva a VSL no banco, confere créditos e chama o gerador de PPTX.
   *
   * @param values
   */
  const handleSubmit = (values: typeof formikInitialValues) => {
    if (state.editor) {
      state.editor
        .save()
        .then(async (outputData: OutputData) => {
          setState({ ...state, isSending: true });

          /**
           * Salva a VSL no banco de dados via api graphql com Apollo
           */
          saveVSL({
            variables: {
              bg_color: values.bgColor,
              bg_image: values.bgImage,
              editor_content: JSON.stringify(outputData),
              font_family: values.fontFamily,
              font_size: values.fontSize,
              layout: values.slideLayout,
              markup_bg_color: values.markupBgColor,
              markup_text_color: values.markupTextColor,
              text_color: values.textColor,
              title: values.vslTitle,
              total_slides: outputData.blocks.length,
              user_id: user?.id,
              vertical_align: values.verticalAlign,
              margin: values.margin,
              text_per_slide: values.textPerSlide,
              uc_words: values.ucWords,
              uc_first: values.ucFirst,
              end_with_ellipsis: values.endWithEllipsis,
            },
          }).then((result: any) => {
            if (result.data.insert_vsls_one.id) {
              const vslId = result.data.insert_vsls_one.id;

              setState({ ...state, isSending: true });

              Mixpanel.track(`VSL draft saved`, {
                vsl_id: vslId,
              });

              getActiveTransactionAndBalance()
                .then((result) => {
                  const transactionId = result.data.transactions[0]?.id ?? "";
                  const unlimited =
                    result.data.transactions[0]?.unlimited ?? false;
                  const packageType =
                    result.data.transactions[0]?.package?.item_type ?? "FREE";
                  const balance =
                    result.data.users[0]?.transactions_aggregate?.aggregate?.sum
                      ?.balance ?? 0;
                  const premiumPackage = result.data.users[0]?.transactions[0]
                    ?.id
                    ? true
                    : false;

                  // O estado é gerado em uma variável separada, que pode ser
                  // usada tanto para atualizar o estado do componente como para
                  // chamar o método generateVSL (setState é assíncrono!).
                  const newState = {
                    ...state,
                    isSending: false,
                    credits: balance,
                    transactionId: transactionId,
                    vslId: vslId,
                    vslText: outputData.blocks,
                    vslProperties: values,
                    isFreeCredit: packageType === "FREE" && !premiumPackage,
                  };

                  setState(newState);

                  Mixpanel.track(`Account balance checked`, {
                    unlimited: unlimited,
                    packageType: packageType,
                    balance: balance,
                    transactionId: transactionId,
                  });

                  if (!unlimited) {
                    onOpenDialogConfirmation();
                  } else {
                    generateVSL(newState);
                  }
                })
                .catch((error) => console.error(error));

              return true;
            } else {
              // TODO: Melhorar mensagem de erro ao gerar VSL
              alert(
                "Não foi possível gerar sua VSL, pode tentar de novo, por favor?"
              );

              console.error("error_response", result);
              return false;
            }
          });
        })
        .catch((error) => {
          console.error("Saving failed: ", error);
        });
    } else {
      console.log("editor", state.editor);
    }
  };

  /**
   * Atualizar o estado do componente, usando o vslState passado como parâmetro, e
   * atualiza os créditos do usuário, chamando o updateVSL.
   *
   * @param vslState Estado que deve ser usado para gerar o VSL e atualizar os créditos.
   */
  const generateVSL = (vslState: NewVslFormState) => {
    setState(vslState);

    updateVSL({
      variables: {
        transaction_id: vslState.transactionId,
        id: vslState.vslId,
      },
    })
      .then(() => {
        setState({ ...state, isSending: false });

        const pptx = new PPTXGen({
          vslText: vslState.vslText,
          slideLayout: vslState.vslProperties!.slideLayout,
          fontSize: vslState.vslProperties!.fontSize,
          textColor: vslState.vslProperties!.textColor,
          bgColor: vslState.vslProperties!.bgColor,
          fontFamily: vslState.vslProperties!.fontFamily,
          markupTextColor: vslState.vslProperties!.markupTextColor,
          markupBgColor: vslState.vslProperties!.markupBgColor,
          bgImage: vslState.vslProperties!.bgImage,
          verticalAlign: vslState.vslProperties!.verticalAlign,
          margin: vslState.vslProperties!.margin,
          textPerSlide: vslState.vslProperties!.textPerSlide,
          vslTitle: vslState.vslProperties!.vslTitle,
          ucWords: vslState.vslProperties!.ucWords,
          ucFirst: vslState.vslProperties!.ucFirst,
          endWithEllipsis: vslState.vslProperties!.endWithEllipsis,
          isPreview: false,
          isFreeCredit: vslState.isFreeCredit,
        });

        pptx.generatePPTX();

        Mixpanel.track(`Generated PPTX`, {
          vsl_id: vslState.vslId,
          vsl_props: vslState.vslProperties,
        });
      })
      .catch((error) => console.error(error));
  };

  /**
   * Faz a geração do preview da VSL, gerando um PPTX com marca d'agua e fazendo
   * uma chamada para o backend converter o PPTX para PDF.
   *
   */
  const generatePreview = async () => {
    if (state.editor) {
      state.editor.save().then(async (outputData: OutputData) => {
        setState({ ...state, isGeneratingPreview: true });

        const pptx = new PPTXGen({
          vslText: outputData.blocks,
          slideLayout: formik.values!.slideLayout,
          fontSize: formik.values!.fontSize,
          textColor: formik.values!.textColor,
          bgColor: formik.values!.bgColor,
          fontFamily: formik.values!.fontFamily,
          markupTextColor: formik.values!.markupTextColor,
          markupBgColor: formik.values!.markupBgColor,
          bgImage: formik.values!.bgImage,
          verticalAlign: formik.values!.verticalAlign,
          margin: formik.values!.margin,
          textPerSlide: formik.values!.textPerSlide,
          vslTitle: `Prévia - ${formik.values!.vslTitle}`,
          ucWords: formik.values!.ucWords,
          ucFirst: formik.values!.ucFirst,
          endWithEllipsis: formik.values!.endWithEllipsis,
          isPreview: true,
          isFreeCredit: true,
        });

        const base64File = await pptx.generatePPTX();

        if (base64File) {
          axios
            .post(
              `${process.env.REACT_APP_REST_API_URL}/app/generate-preview`,
              {
                userId: user?.id,
                base64File: base64File,
              }
            )
            .then(function (response) {
              try {
                setState({ ...state, isGeneratingPreview: false });

                if (response.data.url) {
                  window.open(response.data.url);

                  Mixpanel.track(`Generated VSL preview`, {});
                } else {
                  toast({
                    title: "Algo deu errado",
                    description:
                      "Não conseguimos gerar uma prévia. Pode tentar novamente?",
                    status: "error",
                    isClosable: true,
                    duration: 9000,
                  });
                }
              } catch (error) {
                toast({
                  title: "Algo deu errado",
                  description:
                    "Não conseguimos gerar uma prévia. Pode tentar novamente?",
                  status: "error",
                  isClosable: true,
                  duration: 9000,
                });
              }
            })
            .catch(function (error) {
              toast({
                title: "Algo deu errado",
                description:
                  "Não conseguimos gerar uma prévia. Pode tentar novamente em uma aba anônima?",
                status: "error",
                isClosable: true,
                duration: 9000,
              });
              setState({ ...state, isGeneratingPreview: false });
              console.log("Erro:", error);
            });
        }
      });
    }
  };

  /**
   * Chamado após a confirmação do usuário de que quer realmente gerar a VSL.
   * Primeiro, atualizamos os créditos do usuário e depois geramos o PPTX.
   */
  const onGenerateVSL = () => {
    onCloseDialogConfirmation();
    generateVSL(state);
  };

  /**
   * Atualiza cores no preview.
   *
   * @param event
   */
  const handleFieldAndCSSVariable = async (
    event: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    const value = event.target.value;
    formik.setFieldValue(field, value);
    document.documentElement.style.setProperty(`--${field}`, value);
  };

  /**
   * Seta os valores padrão das variáveis CSS
   */
  document.documentElement.style.setProperty(
    "--bgColor",
    formik.values.bgColor
  );
  document.documentElement.style.setProperty(
    "--textColor",
    formik.values.textColor
  );
  document.documentElement.style.setProperty(
    "--fontFamily",
    formik.values.fontFamily
  );
  document.documentElement.style.setProperty(
    "--markupTextColor",
    formik.values.markupTextColor
  );
  document.documentElement.style.setProperty(
    "--markupBgColor",
    formik.values.markupBgColor
  );

  return (
    <Grid templateColumns="repeat(5, 1fr)" gap={4} pb={8}>
      <GridItem colSpan={{ base: 5, md: 3 }} h="full">
        <Box
          className="editorjs-box"
          boxShadow={useColorModeValue("sm", "sm-dark")}
          borderRadius="lg"
          height="full"
          py={4}
          pl={5}
          pr={4}
          overflowX="visible"
          key={`editorjs-parent-${editorJSId}`}
        >
          <div
            id={editorJSId}
            style={{ height: "75vh", overflowX: "hidden" }}
            key={`editorjs-${editorJSId}`}
          />
        </Box>
      </GridItem>

      <GridItem colSpan={{ base: 5, md: 2 }} h="max-content">
        <Box
          bg="bg-surface"
          boxShadow={useColorModeValue("sm", "sm-dark")}
          borderRadius="lg"
          height="max-content"
        >
          <form onSubmit={formik.handleSubmit}>
            <Stack
              spacing="5"
              px={{ base: "4", md: "6" }}
              py={{ base: "5", md: "6" }}
            >
              <Text fontSize="lg" fontWeight="bold">
                Opções Gerais
              </Text>

              <Stack spacing="4" direction={{ base: "column", md: "row" }}>
                <FormControl>
                  <FormLabel htmlFor="vslTitle">Título da VSL</FormLabel>
                  <Input
                    type="text"
                    id="vslTitle"
                    name="vslTitle"
                    value={formik.values.vslTitle}
                    onChange={formik.handleChange}
                  />
                  <FormHelperText>Usado apenas internamente.</FormHelperText>
                </FormControl>
              </Stack>

              <Stack spacing="4" direction={{ base: "column", md: "row" }}>
                <FormControl>
                  <FormLabel htmlFor="textColor">Cor do Texto</FormLabel>
                  <Input
                    type="color"
                    id="textColor"
                    name="textColor"
                    onChange={(event) =>
                      handleFieldAndCSSVariable(event, "textColor")
                    }
                    value={formik.values.textColor}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="bgColor">Cor de Fundo</FormLabel>
                  <Input
                    type="color"
                    id="bgColor"
                    name="bgColor"
                    onChange={(event) =>
                      handleFieldAndCSSVariable(event, "bgColor")
                    }
                    value={formik.values.bgColor}
                  />
                </FormControl>
              </Stack>

              <Stack spacing="4" direction={{ base: "column", md: "row" }}>
                <FormControl>
                  <FormLabel htmlFor="fontSize">Tamanho do Texto</FormLabel>
                  <Input
                    id="fontSize"
                    name="fontSize"
                    type="number"
                    min={20}
                    max={50}
                    defaultValue={formik.values.fontSize}
                    onChange={formik.handleChange}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="fontFamily">Fonte do Texto</FormLabel>
                  <ReactSelect
                    options={fontOptions}
                    isSearchable={true}
                    name="fontFamily"
                    id="fontFamily"
                    defaultValue={getSelectedOption(
                      fontOptions,
                      formik.values.fontFamily
                    )}
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 9,
                      colors: {
                        ...theme.colors,
                        ...reactSelectThemeColors,
                      },
                    })}
                    onChange={(option: any) => {
                      formik.setFieldValue("fontFamily", option.value);
                      document.documentElement.style.setProperty(
                        "--fontFamily",
                        option.value
                      );
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack spacing="4" direction={{ base: "column", md: "row" }}>
                <FormControl>
                  <FormLabel htmlFor="verticalAlign">
                    Alinhamento do Texto
                  </FormLabel>
                  <ReactSelect
                    options={verticalAlignOptions}
                    isSearchable={true}
                    id="verticalAlign"
                    name="verticalAlign"
                    defaultValue={getSelectedOption(
                      verticalAlignOptions,
                      formik.values.verticalAlign
                    )}
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 9,
                      colors: {
                        ...theme.colors,
                        ...reactSelectThemeColors,
                      },
                    })}
                    onChange={(option: any) => {
                      formik.setFieldValue("verticalAlign", option.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="textPerSlide">
                    Quanto texto por slide?
                  </FormLabel>
                  <ReactSelect
                    options={textPerSlideOptions}
                    isSearchable={true}
                    id="textPerSlide"
                    name="textPerSlide"
                    defaultValue={getSelectedOption(
                      textPerSlideOptions,
                      formik.values.textPerSlide
                    )}
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 9,
                      colors: {
                        ...theme.colors,
                        ...reactSelectThemeColors,
                      },
                    })}
                    onChange={(option: any) => {
                      formik.setFieldValue("textPerSlide", option.value);
                    }}
                  />
                </FormControl>
              </Stack>

              <Stack spacing="4" direction={{ base: "column", md: "row" }}>
                <FormControl>
                  <FormLabel htmlFor="margin">Margens</FormLabel>
                  <ReactSelect
                    options={marginOptions}
                    isSearchable={true}
                    id="margin"
                    name="margin"
                    defaultValue={getSelectedOption(
                      marginOptions,
                      formik.values.margin
                    )}
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 9,
                      colors: {
                        ...theme.colors,
                        ...reactSelectThemeColors,
                      },
                    })}
                    onChange={(option: any) => {
                      formik.setFieldValue("margin", option.value);
                    }}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor="slideLayout">
                    Formato dos Slides
                  </FormLabel>
                  <ReactSelect
                    options={layoutOptions}
                    isSearchable={true}
                    id="slideLayout"
                    name="slideLayout"
                    defaultValue={getSelectedOption(
                      layoutOptions,
                      formik.values.slideLayout
                    )}
                    classNamePrefix="react-select"
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 9,
                      colors: {
                        ...theme.colors,
                        ...reactSelectThemeColors,
                      },
                    })}
                    onChange={(option: any) => {
                      const layout = option.value;
                      const helperText = document.getElementById(
                        "bgImageRecommendedSize"
                      );
                      const sizeMap = {
                        LAYOUT_16x9: "1280x720",
                        LAYOUT_16x10: "1280x800",
                        LAYOUT_4x3: "1280x960",
                        LAYOUT_WIDE: "1920x1080",
                        LAYOUT_SQUARE: "1080x1080",
                      };
                      formik.setFieldValue("slideLayout", layout);

                      if (helperText) {
                        helperText.innerHTML = (sizeMap as any)[layout];
                      }
                    }}
                  />
                </FormControl>
              </Stack>

              <Accordion allowToggle>
                <AccordionItem>
                  <AccordionButton px={0}>
                    <Box flex="1" textAlign="left">
                      <Text fontSize="md" fontWeight="bold">
                        Opções Avançadas
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                  <AccordionPanel py={4} px={0}>
                    <Stack spacing="3">
                      <Text fontSize="md">Destaque</Text>
                      <Stack
                        spacing="4"
                        direction={{ base: "column", md: "row" }}
                      >
                        <FormControl>
                          <FormLabel htmlFor="markupTextColor">
                            Cor do Texto
                          </FormLabel>
                          <Input
                            type="color"
                            id="markupTextColor"
                            name="markupTextColor"
                            onChange={(event) =>
                              handleFieldAndCSSVariable(
                                event,
                                "markupTextColor"
                              )
                            }
                            value={formik.values.markupTextColor}
                          />
                        </FormControl>

                        <FormControl>
                          <FormLabel htmlFor="markupBgColor">
                            Cor de Fundo
                          </FormLabel>
                          <Input
                            type="color"
                            id="markupBgColor"
                            name="markupBgColor"
                            onChange={(event) =>
                              handleFieldAndCSSVariable(event, "markupBgColor")
                            }
                            value={formik.values.markupBgColor}
                          />
                        </FormControl>
                      </Stack>

                      <Text fontSize="md">Background</Text>
                      <Stack
                        spacing="4"
                        direction={{ base: "column", md: "row" }}
                      >
                        <FormControl>
                          <FormLabel htmlFor="bgImageUpload">
                            Imagem de Fundo
                          </FormLabel>
                          <Input
                            type="file"
                            accept="image/png, image/jpeg"
                            id="bgImageUpload"
                            p="1"
                            onChange={async (event) => {
                              if (event.target.files?.length) {
                                const base64 = await fileAsBase64(
                                  event.target.files[0]
                                );
                                formik.setFieldValue("bgImage", base64);
                              }
                            }}
                          />
                          <FormHelperText>
                            Dimensões recomendadas:{" "}
                            <span id="bgImageRecommendedSize">1280x720</span>
                          </FormHelperText>
                        </FormControl>

                        <Input
                          type="hidden"
                          id="bgImage"
                          name="bgImage"
                          value={formik.values.bgImage}
                          onChange={formik.handleChange}
                        ></Input>
                      </Stack>

                      <Text fontSize="md">Outros ajustes de texto</Text>

                      <CheckboxGroup colorScheme="brand">
                        <Stack spacing={2} direction={"column"}>
                          <Checkbox
                            id="ucWords"
                            name="ucWords"
                            isChecked={formik.values.ucWords}
                            onChange={formik.handleChange}
                          >
                            Converter Iniciais Para Maiúsculo
                          </Checkbox>
                          <Checkbox
                            id="ucFirst"
                            name="ucFirst"
                            isChecked={formik.values.ucFirst}
                            onChange={formik.handleChange}
                          >
                            Primeira letra maiúscula em cada slide
                          </Checkbox>
                          <Checkbox
                            id="endWithEllipsis"
                            name="endWithEllipsis"
                            isChecked={formik.values.endWithEllipsis}
                            onChange={formik.handleChange}
                          >
                            Terminar slides com reticências...
                          </Checkbox>
                        </Stack>
                      </CheckboxGroup>
                    </Stack>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Stack>
            <Divider />

            <Flex direction="row-reverse" py="4" px={{ base: "4", md: "6" }}>
              <Button
                isLoading={state.isSending}
                loadingText="Quase lá"
                type="submit"
                variant="primary"
                rightIcon={<HiCursorClick />}
              >
                Gerar VSL
              </Button>
              <Button
                type="button"
                variant="ghost"
                isLoading={state.isGeneratingPreview}
                rightIcon={<HiOutlineEye />}
                mr="2"
                onClick={generatePreview}
              >
                Ver Prévia
              </Button>
            </Flex>
          </form>
        </Box>
      </GridItem>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelAlertRef}
        onClose={onCloseDialogConfirmation}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Gerar VSL?
            </AlertDialogHeader>

            <AlertDialogBody>
              {state.credits > 0 ? (
                <div>
                  Você possui {state.credits} crédito(s) em sua conta. A geração
                  de uma VSL consumirá 1 crédito. Tem certeza que gostaria de
                  gerar sua VSL?
                </div>
              ) : (
                <div>
                  Você não possui mais créditos para gerar VSLs.{" "}
                  <b>
                    <Link to="/credits">Clique aqui para adicionar.</Link>
                  </b>
                </div>
              )}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelAlertRef} onClick={onCloseDialogConfirmation}>
                {state.credits > 0 ? "Cancelar" : "Fechar"}
              </Button>
              {state.credits > 0 ? (
                <Button colorScheme="red" onClick={onGenerateVSL} ml={3}>
                  Gerar
                </Button>
              ) : undefined}
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Grid>
  );
};

export default NewVslForm;
