import { useQuery } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Radio,
  RadioGroup,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import securePayment from "../../assets/img/compra-segura.png";
import { useState } from "react";

import { HiOutlineShoppingCart } from "react-icons/hi";
import FullScreenLoader from "../../components/FullScreenLoader";
import { useAuth } from "../../contexts/auth";
import { GET_TRANSACTIONS } from "../../graphql/queries";
import {
  packageOptions,
  packageOptionsBlackFriday,
} from "./options/package-options";
import { isTodayBefore } from "../../utils/helpers";
import { Mixpanel } from "../../utils/mixpanel";

/**
 * Type Transaction
 */
type Transaction = {
  balance: number;
  credits: number;
  description: string;
  expires_on: string;
  id: string;
  transaction_status: {
    value: string;
    description: string;
  };
  unlimited: boolean;
  usages: number;
};

/**
 * Type PackageOption
 */
type PackageOption = {
  id: number;
  base_credits?: number;
  credits: number;
  total_price: number;
  base_credit_price?: number;
  credit_price: number;
  buy_url: string;
};

const CreditsPage = () => {
  const { user } = useAuth();
  const headingSize = useBreakpointValue({ base: "xs", lg: "sm" });
  const boxShadow = useColorModeValue("sm", "sm-dark");

  const isBeforeNewYear = isTodayBefore("2023-01-01");

  /**
   * Opções de pacote válidas para a data atual
   */
  const creditsOptions: Array<PackageOption> = isBeforeNewYear
    ? packageOptionsBlackFriday
    : packageOptions;

  /**
   * State
   */
  const [selected, setSelected] = useState({
    index: 0,
    credit_price: creditsOptions[0].credit_price,
    base_credit_price: creditsOptions[0].base_credit_price,
    buy_url: creditsOptions[0].buy_url,
    credits: creditsOptions[0].credits,
  });

  /**
   * Graphql
   */
  const { loading, error, data } = useQuery(GET_TRANSACTIONS, {
    fetchPolicy: "no-cache",
  });

  if (loading) return <FullScreenLoader />;
  if (error) return <Text>Erro! {error.message}</Text>;

  return (
    <Stack spacing={{ base: "8", lg: "6" }} height="full">
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        align={{ base: "start", lg: "center" }}
      >
        <Stack spacing="1">
          <Heading size={headingSize} fontWeight="black">
            Créditos
          </Heading>
          <Text color="muted">
            Gerencie seus créditos ativos e compre novos créditos para gerar
            VSLs.
          </Text>
        </Stack>
      </Stack>

      <Grid templateColumns="repeat(6, 1fr)" gap={4}>
        <GridItem colSpan={{ base: 6, md: 3 }} h="full">
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Meus Pacotes
          </Text>

          {data.transactions.map((transaction: Transaction) => (
            <Box
              bg="bg-surface"
              boxShadow={boxShadow}
              borderRadius="lg"
              p={4}
              mb={3}
              key={transaction.id}
            >
              <Flex>
                <Center>
                  <Stack spacing={1}>
                    <Text>
                      {transaction.description}
                      <Badge
                        fontSize="xs"
                        ml="1"
                        px="2"
                        py="0"
                        colorScheme={
                          transaction.transaction_status.value === "ACTIVE"
                            ? "green"
                            : "gray"
                        }
                      >
                        {transaction.transaction_status.description}
                      </Badge>
                    </Text>
                    <Text fontSize={"xs"} opacity="0.6">
                      Válido até:{" "}
                      {new Date(transaction.expires_on).toLocaleString()}
                    </Text>
                  </Stack>
                </Center>
                <Spacer />
                <Center>
                  <Text fontSize="2xl">{transaction.usages}</Text>
                  <Text fontSize="sm">/</Text>
                  <Text fontSize="lg">
                    {transaction.unlimited ? "∞" : transaction.credits}
                  </Text>
                </Center>
              </Flex>
            </Box>
          ))}
        </GridItem>

        <GridItem colSpan={{ base: 6, md: 3 }} h="max-content">
          <Text fontSize="xl" fontWeight="bold" mb={4}>
            Comprar Créditos
          </Text>
          <Box bg="bg-surface" boxShadow={boxShadow} borderRadius="lg" p={4}>
            {selected.base_credit_price ? (
              <HStack style={{ marginBottom: "-7px" }}>
                <Text fontSize="md" color={"red.400"}>
                  De
                </Text>
                <Text fontSize="xl" as="s" color={"red.400"}>
                  {selected.base_credit_price.toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL",
                  })}
                </Text>
                <Text fontSize="xs" color={"teal.400"}>
                  por
                </Text>
              </HStack>
            ) : (
              ""
            )}
            <HStack alignItems={"baseline"} mb={3} spacing={1}>
              <Text fontSize="3xl" fontWeight="bold" color={"teal.400"}>
                {selected.credit_price.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                })}
              </Text>
              <Text fontSize="xs" color={"teal.400"}>
                / por VSL
              </Text>
            </HStack>

            <RadioGroup
              onChange={(value) => {
                const index: number = Number(value);
                setSelected({
                  index: index,
                  credit_price: creditsOptions[index].credit_price,
                  base_credit_price: creditsOptions[index].base_credit_price,
                  buy_url: creditsOptions[index].buy_url,
                  credits: creditsOptions[index].credits,
                });
              }}
              value={selected.index}
              mb={5}
            >
              <Stack>
                {creditsOptions.map((option: PackageOption, index) => (
                  <Flex
                    justifyContent={"space-between"}
                    key={`package-option-${option.id}`}
                  >
                    <Box>
                      <Radio value={index}>
                        <HStack justifyContent={"space-around"}>
                          {option.base_credits ? (
                            <Text as="s" color={"gray.400"}>
                              {option.base_credits}
                            </Text>
                          ) : (
                            ""
                          )}
                          <Box>
                            {option.credits}
                            {option.credits > 1 ? " créditos" : " crédito"}
                          </Box>
                        </HStack>
                      </Radio>
                    </Box>
                    <Box>
                      <Text textAlign={"left"}>
                        {option.total_price.toLocaleString("pt-BR", {
                          style: "currency",
                          currency: "BRL",
                        })}
                      </Text>
                    </Box>
                  </Flex>
                ))}
              </Stack>
            </RadioGroup>

            <Button
              variant="primary"
              rightIcon={<HiOutlineShoppingCart />}
              onClick={() => {
                const queryString = new URLSearchParams({
                  name: user?.name ?? "",
                  email: user?.email ?? "",
                });

                Mixpanel.track(`Clicked buy button`, {
                  buy_url: selected.buy_url,
                  quantity: selected.credits,
                  price: selected.credit_price,
                });

                window.open(`${selected.buy_url}?${queryString}`, "_blank");
              }}
            >
              Comprar Agora
            </Button>
          </Box>

          <Box
            backgroundColor="bg-surface"
            padding={3}
            mt={4}
            borderRadius="lg"
            boxShadow={boxShadow}
          >
            <Image src={securePayment} mb={2} title="Compra Segura" />

            <Text fontSize={"xs"}>
              Faça sua compra usando o e-mail <b>{user?.email}</b>, assim
              identificaremos automaticamente seu pedido e liberaremos os
              créditos assim que o pagamento for aprovado.
            </Text>
          </Box>

          <Box
            backgroundColor="bg-surface"
            padding={3}
            mt={4}
            borderRadius="lg"
            boxShadow={boxShadow}
          >
            <Text fontSize={"xs"}>
              Todos os créditos são válidos pelo período de <b>2 anos</b>.
            </Text>
          </Box>
        </GridItem>
      </Grid>
    </Stack>
  );
};

export default CreditsPage;
