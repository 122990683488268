import { useQuery } from "@apollo/client";
import {
  Box,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import FullScreenLoader from "../../components/FullScreenLoader";
import { GET_VSL_BY_ID } from "../../graphql/queries";

import NewVslForm from "./NewVslForm";

const NewVslPage = () => {
  const { vslId } = useParams();
  const { loading, error, data } = useQuery(GET_VSL_BY_ID, {
    variables: {
      vsl_id: vslId ? vslId : "aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa",
    },
  });

  const headingSize = useBreakpointValue({ base: "xs", lg: "sm" });
  const boxShadow = useColorModeValue("sm", "sm-dark");

  if (loading) return <FullScreenLoader />;
  if (error) return <Text>Erro! {error.message}</Text>;

  const vsl = data.vsls_by_pk;

  return (
    <Stack spacing={{ base: "8", lg: "6" }} height="full">
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        align={{ base: "start", lg: "center" }}
      >
        <Stack spacing="1">
          <Heading size={headingSize} fontWeight="black">
            Nova VSL
          </Heading>
          <Text color="muted">
            Preencha os campos abaixo para gerar sua Video Sales Letter (VSL).
          </Text>
        </Stack>
      </Stack>

      <NewVslForm baseVsl={vsl} />

      <Box bg="bg-surface" boxShadow={boxShadow} borderRadius="lg"></Box>
    </Stack>
  );
};

export default NewVslPage;
