import { CircularProgress, Stack } from "@chakra-ui/react";

const FullScreenLoader = () => {
  return (
    <Stack
      width="full"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        isIndeterminate
        color="teal.400"
        size="75px"
        thickness="10px"
      />
    </Stack>
  );
};

export default FullScreenLoader;
