export const colors: {} = {
  brand: {
    900: "#0a6a63",
    800: "#0c7f76",
    700: "#0e948a",
    600: "#10a99e",
    500: "#12beb1",
    400: "#14d3c5",
    300: "#2cd7cb",
    200: "#43dcd1",
    100: "#5be0d6",
    50: "#72e5dc",
  },
};
