import {
  Link,
  Menu,
  MenuDivider,
  MenuItem,
  MenuList,
  useColorModeValue,
} from "@chakra-ui/react";

import { useAuth } from "../contexts/auth";
import { AccountSwitcherButton } from "./AccountSwitcherButton";
import classes from "./layout.module.css";
import profilePic from "../assets/img/profile.jpg";

export const AccountSwitcher = () => {
  const { user, logout } = useAuth();

  const onLogout = () => {
    logout();
  };

  return (
    <Menu>
      <AccountSwitcherButton
        name={user?.name ?? ""}
        image={profilePic}
        email={user?.email ?? ""}
      />
      <MenuList
        shadow="lg"
        py="4"
        color={useColorModeValue("gray.600", "gray.200")}
        px="3"
      >
        <MenuItem
          as={Link}
          href="https://www.orbitpages.com"
          isExternal
          fontSize="sm"
          rounded="md"
          className={classes.noDecoration}
        >
          Orbit Pages
        </MenuItem>
        <MenuItem
          as={Link}
          href="https://www.orbit.academy"
          isExternal
          fontSize="sm"
          rounded="md"
          className={classes.noDecoration}
        >
          Orbit Academy
        </MenuItem>
        <MenuItem
          as={Link}
          href="https://blog.orbit.com.vc"
          isExternal
          fontSize="sm"
          rounded="md"
          className={classes.noDecoration}
        >
          Blog da Orbit
        </MenuItem>
        <MenuDivider />
        <MenuItem rounded="md" fontSize="sm" onClick={onLogout}>
          Sair
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
