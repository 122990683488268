import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginPage from "../pages/LoginPage/LoginPage";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";

const LoginRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/auth">
          <Route path="login" element={<LoginPage></LoginPage>}></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Route>
        <Route path="*" element={<Navigate to="/auth/login" />}></Route>
      </Routes>
    </BrowserRouter>
  );
};

export default LoginRoutes;
