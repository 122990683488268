import { CircularProgress, Stack } from "@chakra-ui/react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/auth";

const LoginPage = () => {
  const location = useLocation();
  const { validate, redirect, signedIn } = useAuth();
  const navigate = useNavigate();

  if (signedIn) {
    navigate("/new-vsl");
  }

  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");

  if (token) {
    validate(token)
      .then((isValid) => {
        if (!isValid) {
          redirect("Faça login novamente, por favor");
        } else {
          navigate("/new-vsl");
        }
      })
      .catch(() => {
        redirect("Ops, tente novamente por favor");
      });
  } else {
    redirect("Faça login para continuar");
  }

  return (
    <Stack
      width="full"
      minHeight="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress
        isIndeterminate
        color="brand.400"
        size="75px"
        thickness="10px"
      />
    </Stack>
  );
};

export default LoginPage;
