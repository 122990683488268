import { Heading, Image, Stack, Text } from "@chakra-ui/react";
import notFoundImage from "../../assets/img/404.svg";

const NotFoundPage = () => {
  return (
    <Stack p={4} align="center">
      <Image src={notFoundImage} alt="404" maxWidth="container.lg" />
      <Heading as="h4" size="md">
        Nada aqui ainda
      </Heading>
      <Text fontSize="lg">
        Você descobriu uma página tão nova que ainda não existe, parabéns!
      </Text>
    </Stack>
  );
};

export default NotFoundPage;
