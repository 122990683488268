import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { CognitoJwtVerifier } from "aws-jwt-verify";
import { useMutation } from "@apollo/client";
import { UPSERT_USER } from "../graphql/mutations";
import { Mixpanel } from "../utils/mixpanel";

type AuthContextType = {
  signedIn: boolean;
  user: User | null;
  validate(token: string): Promise<boolean>;
  logout(): Promise<boolean>;
  redirect(messageText?: string): void;
};

interface AuthProviderProps {
  children: ReactNode;
}

export class User {
  id: string;
  name: string;
  email: string;

  constructor(id: string, name: string, email: string) {
    this.id = id;
    this.name = name;
    this.email = email;
  }

  nameParts() {
    const fullName = this.name.split(" "),
      firstName = fullName[0],
      lastName = fullName[fullName.length - 1];

    return {
      first: firstName,
      last: lastName !== firstName ? lastName : "",
    };
  }
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [user, setUser] = useState<User | null>(null);
  const [upsertUser] = useMutation(UPSERT_USER);

  useEffect(() => {
    const token = localStorage.getItem("@App:Token");

    if (token) {
      validate(token);
    }
  }, []);

  async function logout(): Promise<boolean> {
    localStorage.removeItem("@App:Token");
    Mixpanel.track(`Logout`, {});
    setUser(null);
    redirect();
    return true;
  }

  async function validate(token: string): Promise<boolean> {
    const verifier = CognitoJwtVerifier.create({
      userPoolId: "us-east-1_XHXAiRNJ5",
      tokenUse: "id",
      clientId: "1cf18vm6q6o33gr1633an0qg2q",
    });

    try {
      const payload = await verifier.verify(token);

      // TODO: Está renderizando 3 vezes

      localStorage.setItem("@App:Token", token);

      /**
       * Faz update ou cria o usuário no banco de dados
       */
      const success = await upsertUser({
        variables: {
          email: payload.email,
          name: payload.name,
          orbit_account_id: payload.sub,
        },
      }).then((result) => {
        Mixpanel.identify(payload.email);

        if (result.data.insert_users_one.id) {
          setUser(
            new User(
              `${result.data.insert_users_one.id}`,
              `${payload.name}`,
              `${payload.email}`
            )
          );

          Mixpanel.track(`Login`, {});
          return true;
        } else {
          Mixpanel.track(`Failed Login`, {});
          return false;
        }
      });

      // precisa chamar de novo pois precisamos do token antes para autenticar a mutation
      if (!success) {
        localStorage.removeItem("@App:Token");
      }

      return success;
    } catch {
      return false;
    }
  }

  /**
   * Faz o redirect para a tela de login passando uma mensagem
   *
   * @param messageText
   * @returns
   */
  function redirect(messageText?: string) {
    let accountsUrl = process.env.REACT_APP_LOGIN_URL;

    if (accountsUrl === undefined) {
      return;
    }

    if (messageText) {
      const queryString = new URLSearchParams({
        message: messageText,
      });

      accountsUrl = `${accountsUrl}?${queryString}`;
    }

    Mixpanel.track(`Redirect to login`, {});

    window.location.replace(accountsUrl);
  }

  return (
    <AuthContext.Provider
      value={{ signedIn: Boolean(user), user, validate, logout, redirect }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  return useContext(AuthContext);
}

export default AuthContext;
