import Underline from "@editorjs/underline";
import Marker from "@editorjs/marker";

const editorJSOptions = {
  minHeight: 0,
  tools: {
    Marker: {
      class: Marker,
      shortcut: "CMD+SHIFT+M",
    },
    underline: Underline,
    paragraph: {
      inlineToolbar: ["bold", "italic", "underline", "Marker"],
    },
  },
  i18n: {
    /**
     * @type {I18nDictionary}
     */
    messages: {
      ui: {
        blockTunes: {
          toggler: {
            "Click to tune": "Clique para mover",
            "or drag to move": "ou arraste para mover",
          },
        },
        inlineToolbar: {
          converter: {
            "Convert to": "Transformar em",
          },
        },
        toolbar: {
          toolbox: {
            Add: "Adicionar",
          },
        },
      },
      toolNames: {
        Text: "Texto",
        Marker: "Destaque",
        Bold: "Negrito",
        Italic: "Itálico",
        Underline: "Sublinhar",
      },
      blockTunes: {
        delete: {
          Delete: "Remover",
        },
        moveUp: {
          "Move up": "Mover para cima",
        },
        moveDown: {
          "Move down": "Mover para baixo",
        },
      },
    },
  },
};

export default editorJSOptions;
