import { gql } from "@apollo/client";

export const UPSERT_USER = gql`
  mutation UpsertUser(
    $email: String!
    $name: String!
    $orbit_account_id: String!
  ) {
    insert_users_one(
      object: {
        email: $email
        name: $name
        orbit_account_id: $orbit_account_id
      }
      on_conflict: {
        constraint: users_orbit_account_id_key
        update_columns: name
      }
    ) {
      id
      email
      name
      orbit_account_id
    }
  }
`;

export const SAVE_VSL = gql`
mutation SaveVSL(
  $bg_color: String!
  $bg_image: String = ""
  $editor_content: jsonb = ""
  $font_family: String!
  $font_size: Int = 40
  $layout: vsl_layouts_enum = LAYOUT_16x10
  $markup_bg_color: String!
  $markup_text_color: String!
  $text_color: String!
  $title: String = ""
  $total_slides: Int = 0
  $user_id: uuid!
  $vertical_align: vsl_vertical_align_enum = BOTTOM
  $margin: vsl_margins_enum = SMALL
  $text_per_slide: vsl_text_per_slide_enum = DEFAULT
  $uc_words: Boolean!
  $uc_first: Boolean!
  $end_with_ellipsis: Boolean!
) {
  insert_vsls_one(
    object: {
      bg_color: $bg_color
      bg_image: $bg_image
      editor_content: $editor_content
      font_family: $font_family
      font_size: $font_size
      layout: $layout
      markup_bg_color: $markup_bg_color
      markup_text_color: $markup_text_color
      text_color: $text_color
      title: $title
      total_slides: $total_slides
      user_id: $user_id
      vertical_align: $vertical_align
      margin: $margin
      text_per_slide: $text_per_slide
      uc_words: $uc_words
      uc_first: $uc_first
      end_with_ellipsis: $end_with_ellipsis
    }
  ) {
    id
    title
    created_at
  }
}
`;

export const UPDATE_VSL = gql`
mutation UpdateVSL(
  $transaction_id: uuid!
  $status: vsl_status_enum = GENERATED
  $id: uuid!
) {
  update_vsls(
    where: { id: { _eq: $id } }
    _set: { status: $status, transaction_id: $transaction_id }
  ) {
    affected_rows
  }
}
`;