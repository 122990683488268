import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import WorkInProgress from "../components/WorkInProgress";
import Shell from "../layout/Shell";
import CreditsPage from "../pages/CreditsPage/CreditsPage";
import MyVslPage from "../pages/MyVslPage/MyVslPage";
import HelpPage from "../pages/HelpPage/HelpPage";
import NewVslPage from "../pages/NewVslPage/NewVslPage";
import RegenerateVSL from "../pages/NewVslPage/RegenerateVSL";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import { Mixpanel } from "../utils/mixpanel";
import { useAuth } from "../contexts/auth";
import { getFirstPath } from "../utils/helpers";
import Zendesk, { ZendeskIndentify } from "../utils/Zendesk";

const AppRoutes = () => {
  const { user } = useAuth();
  const path = getFirstPath();

  /**
   * Zendesk
   */

  const ZENDESK_KEY = "9dfccfd2-bc3c-4baf-9af3-06f7ae18dad1";

  const zendeskSettings = {
    color: {
      theme: "#14D3C5",
      launcher: "#718096",
      launcherText: "#ffffff",
    },
  };

  const handleZendeskLoaded = () => {
    ZendeskIndentify({
      name: user?.name,
      email: user?.email,
    });
  };

  /**
   * Mixpanel
   */
  Mixpanel.identify(user?.email);

  Mixpanel.people.set({
    $first_name: user?.nameParts().first,
    $last_name: user?.nameParts().last,
    $email: user?.email,
    USER_ID: user?.email,
  });

  Mixpanel.track(`Page view`, {
    path: path,
  });

  Mixpanel.track(`${path} page view`, {
    path: path,
  });

  /**
   * Sentry
   */
  Sentry.setUser({ email: user?.email });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Shell />}>
          <Route index element={<Navigate to="/dashboard" />}></Route>
          <Route path="dashboard" element={<MyVslPage />}></Route>
          <Route path="new-vsl" element={<NewVslPage />}></Route>
          <Route path="copy-vsl/:vslId" element={<NewVslPage />}></Route>
          <Route path="regenerate/:vslId" element={<RegenerateVSL />}></Route>
          <Route path="credits" element={<CreditsPage />}></Route>
          <Route path="help" element={<HelpPage />}></Route>
          <Route
            path="resources"
            element={<WorkInProgress centralized={true} />}
          ></Route>
          <Route path="*" element={<NotFoundPage />}></Route>
        </Route>
      </Routes>
      <Zendesk
        defer
        zendeskKey={ZENDESK_KEY}
        {...zendeskSettings}
        onLoaded={handleZendeskLoaded}
      />
    </BrowserRouter>
  );
};

export default AppRoutes;
