import {
  Box,
  Flex,
  FlexProps,
  HStack,
  Img,
  useColorModeValue,
  useMenuButton,
} from "@chakra-ui/react";
import * as React from "react";
import { HiSelector } from "react-icons/hi";

interface UserProfileProps extends FlexProps {
  name: string;
  image: string;
  email: string;
}

export const AccountSwitcherButton = (props: UserProfileProps) => {
  const buttonProps = useMenuButton(props);
  const { name, image, email } = props;
  return (
    <Flex
      as="button"
      {...buttonProps}
      w="full"
      display="flex"
      alignItems="center"
      rounded="lg"
      bg={useColorModeValue("gray.50", "gray.700")}
      px="3"
      py="2"
      fontSize="sm"
      userSelect="none"
      cursor="pointer"
      outline="0"
      transition="all 0.2s"
      _active={{ bg: useColorModeValue("gray.100", "gray.600") }}
      _focus={{ shadow: "outline" }}
    >
      <HStack flex="1" spacing="3">
        <Img
          w="8"
          h="8"
          rounded="md"
          objectFit="cover"
          src={image}
          alt={name}
        />
        <Box textAlign="start">
          <Box noOfLines={1} fontWeight="semibold">
            {name}
          </Box>
          <Box noOfLines={1} fontSize="xs">
            {email}
          </Box>
        </Box>
      </HStack>
      <Box fontSize="lg">
        <HiSelector />
      </Box>
    </Flex>
  );
};
