import {
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { Logo } from "./Logo";
import { NavButton } from "./NavButton";

import {
  HiFolderAdd,
  HiHome,
  HiQuestionMarkCircle,
  HiReceiptRefund,
} from "react-icons/hi";
import { AccountSwitcher } from "./AccountSwitcher";
import { isTodayBefore } from "../utils/helpers";
import { NavLink } from "react-router-dom";

export const Sidebar = () => {
  const isBeforeNewYear = isTodayBefore("2023-01-01");

  return (
    <Flex as="section" minH="100vh" bg="bg-canvas">
      <Flex
        flex="1"
        bg="bg-surface"
        overflowY="auto"
        maxW={{ base: "full", sm: "20rem" }}
        py={{ base: "6", sm: "8" }}
        px={{ base: "4", sm: "6" }}
        minH="100vh"
        position={"fixed"}
      >
        <Stack justify="space-between" spacing="1">
          <Stack spacing={{ base: "5", sm: "6" }} shouldWrapChildren>
            <Logo />

            <Stack spacing="1" mt="2">
              <NavButton label="Minhas VSL" icon={HiHome} to="/dashboard" />
              <NavButton label="Nova VSL" icon={HiFolderAdd} to="/new-vsl" />
              <NavButton
                label="Créditos"
                icon={HiReceiptRefund}
                to="/credits"
              />
            </Stack>
          </Stack>

          <Stack spacing={{ base: "5", sm: "6" }}>
            <Stack spacing="1">
              <ColorModeSwitcher />
            </Stack>

            {isBeforeNewYear ? (
              <Box bg="gray.900" px="4" py="5" borderRadius="lg">
                <Stack spacing="3">
                  <Stack spacing="1">
                    <Stack spacing="0">
                      <Text color="white" fontSize="sm" fontWeight="bold">
                        Ganhe Créditos em Dobro
                      </Text>
                      <Text color="yellow.400" fontSize="md" fontWeight="bold">
                        Gere 2X mais VSL
                      </Text>
                    </Stack>
                    <Text color="whiteAlpha.700" fontSize="sm">
                      Até a virada, seu crédito vale o dobro!
                    </Text>
                  </Stack>
                  <HStack spacing="3">
                    <Button
                      colorScheme="yellow"
                      size="sm"
                      as={NavLink}
                      to="/credits"
                    >
                      Quero 2x mais créditos
                    </Button>
                  </HStack>
                </Stack>
              </Box>
            ) : (
              <Box bg="bg-subtle" px="4" py="5" borderRadius="lg">
                <Stack spacing="4">
                  <Stack spacing="1">
                    <Text fontSize="sm" fontWeight="bold">
                      Conhece o Orbit Pages?
                    </Text>
                    <Text fontSize="sm" color="muted">
                      A ferramenta fácil para construir páginas que vendem.
                    </Text>
                  </Stack>
                  <HStack spacing="3">
                    <Link
                      href="https://www.orbitpages.com"
                      fontSize="sm"
                      isExternal
                    >
                      Conhecer
                    </Link>
                  </HStack>
                </Stack>
              </Box>
            )}
            <Divider />
            <AccountSwitcher />
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  );
};
