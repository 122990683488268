/**
 * Gera base64 de um arquivo
 *
 * @param file
 * @returns
 */
export const fileAsBase64 = (file: any) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

/**
 * Divide um array em partes
 *
 * @param arr
 * @param size
 * @returns
 */
export const chunk = (arr: any[], size: number) => {
  return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) =>
    arr.slice(i * size, i * size + size)
  );
};

/**
 * Converte a primeira letra de uma palavra para maiúsculo.
 *
 * @param s
 * @returns
 */
export const capitalize = (s: string) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

/**
 * Checa se hoje é menor do que uma outra data.
 *
 * @param date
 * @returns boolean
 */
export const isTodayBefore = (date: string) => {
  let today = new Date().getTime();
  let target = new Date(date).getTime();

  return today < target;
};

/**
 * Retorna o path principal da url atual.
 *
 * @returns
 */
export const getFirstPath = () => {
  const pathArray = window.location.pathname.split("/");
  return pathArray[1];
};
