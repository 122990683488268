import { Heading, Image, Stack, Text } from "@chakra-ui/react";
import workInProgressImage from "../assets/img/WorkInProgress.svg";

const WorkInProgress: React.FC<{ centralized?: boolean }> = (props) => {
  return (
    <Stack p={4} align="center">
      <Image
        src={workInProgressImage}
        alt="Em andamento"
        maxWidth="lg"
        mb="4"
        mt={props.centralized ? "14" : "0"}
      />
      <Heading as="h4" size="sm">
        Construção em andamento!
      </Heading>
      <Text fontSize="lg">
        Você descobriu uma página tão nova que ainda não existe, parabéns!
      </Text>
    </Stack>
  );
};

export default WorkInProgress;
