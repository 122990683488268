import pptxgen from "pptxgenjs";
import { chunk, capitalize } from "./helpers";
import darkLogo from "../assets/img/logo-dark.png";

class PPTXGen {
  options: PPTXOptions;
  constructor(options: PPTXOptions) {
    this.options = options;
  }

  /**
   * Gera o arquivo PPTX com os slides VSL.
   *
   * @returns
   */
  generatePPTX() {
    let pptx = new pptxgen();

    pptx.author = "Gerador de VSL";
    pptx.company = "Orbit";
    pptx.revision = "1";
    pptx.subject = "Minha Video Sales Letter";
    pptx.title = "Minha VSL";

    /**
     * Cria os layouts customizados necessários
     */
    pptx.defineLayout({ name: "LAYOUT_SQUARE", width: 11.3, height: 11.3 });

    /**
     * Define o layout selecionado pelo usuário
     */
    pptx.layout = this.options.slideLayout;

    /**
     * Define as margens dos slides
     */
    let marginDefaults: any = {
      x: "3%",
      y: "4%",
      w: "94%",
      h: "92%",
    };
    switch (this.options.margin) {
      // case "SMALL": já setado
      case "MEDIUM":
        marginDefaults = {
          x: "6%",
          y: "9%",
          w: "88%",
          h: "82%",
        };
        break;
      case "LARGE":
        marginDefaults = {
          x: "12%",
          y: "16%",
          w: "76%",
          h: "68%",
        };
        break;
    }

    let slides: any[] = [];

    /**
     * Faz o loop pelos parágrafos vindos do editorjs
     */
    this.options.vslText.forEach((slideText) => {
      // remove html entities
      let originalString = slideText.data.text.replaceAll("&nbsp;", " ");

      // adiciona um espaço após o fechamento de tags
      originalString = originalString.replaceAll(/((<\/)\w+(>))/g, "$1 ");

      // remove espaços antes de fechamento de tags
      originalString = originalString.replaceAll(" </", "</");

      // adiciona espaços depois da tag <br>
      originalString = originalString.replaceAll("<br>", "<br> ");

      // substitui \n por espaços
      originalString = originalString.replaceAll("\n", " ");

      // remove propriedades das tags html
      let strippedString = originalString.replace(
        /<([a-z][a-z0-9]*)[^>]*?(\/?)>/g,
        "<$1$2>"
      );

      // remove espaço em branco do inicio e fim do texto
      strippedString = strippedString.trim();

      const words = strippedString.split(" ");

      /**
       * Para cada palavra, fazemos a checagem dos estilos que devemos usar
       */
      let wordsCollection: Word[] = [];
      let hasBold: WordStyleTag = { current: false, next: false },
        hasItalic: WordStyleTag = { current: false, next: false },
        hasUnderline: WordStyleTag = { current: false, next: false },
        hasMark: WordStyleTag = { current: false, next: false },
        hasLinebreak: boolean;

      // loop nas palavras, inserindo cada uma dentro de wordsCollection
      words.forEach((word: string, index: number) => {
        hasBold = this.getWordFormating(hasBold.next, word, "b");
        hasItalic = this.getWordFormating(hasItalic.next, word, "i");
        hasUnderline = this.getWordFormating(hasUnderline.next, word, "u");
        hasMark = this.getWordFormating(hasMark.next, word, "mark");
        hasLinebreak = word.includes("<br>");

        wordsCollection.push({
          value: word.replace(/(<([^>]+)>)/gi, ""),
          hasBold: hasBold.current,
          hasItalic: hasItalic.current,
          hasUnderline: hasUnderline.current,
          hasMarkdown: hasMark.current,
          hasLinebreak: hasLinebreak,
        });

        // se não é a última palavra da lista, não está vazio e não vem de uma
        // quebra de linha, adiciona um espaço na sequencia, respeitando a
        // formatação que já sabemos que a próxima palavra deve manter

        if (index < words.length - 1 && !hasLinebreak && word.length) {
          // Se o próximo item for uma pontuação, não adiciona
          let punctuation = /^[!?.,](?:\s+)?$/g;

          if (!punctuation.test(words[index + 1])) {
            wordsCollection.push({
              value: " ",
              hasBold: hasBold.next,
              hasItalic: hasItalic.next,
              hasUnderline: hasUnderline.next,
              hasMarkdown: hasMark.next,
              hasLinebreak: false,
            });
          }
        }
      });

      // Se for a configuração padrão, respeitamos as linhas definidas pelo usuário
      if (this.options.textPerSlide === "DEFAULT") {
        slides.push(wordsCollection);
        return;
      }

      /**
       * Texto por slide
       */
      const wordCount = wordsCollection.length;
      let maxWords;

      // usamos aqui números maiores, pois também contabilizamos espaços
      switch (this.options.textPerSlide) {
        case "LITTLE":
          maxWords = 18;
          break;
        case "MEDIUM":
          maxWords = 28;
          break;
        default:
          maxWords = 40;
          break;
      }

      // Confere se tem mais palavras do que o "permitido", e se sobram mais do que
      // 3 palavras, para evitar um slide com quase nenhum texto
      if (wordCount > maxWords && Math.abs(wordCount - maxWords) > 3) {
        const slidesToBreakTo = Math.ceil(wordCount / maxWords);
        const wordsPerSlide = Math.ceil(wordCount / slidesToBreakTo);

        let newSlides = chunk(wordsCollection, wordsPerSlide);

        // faz o loop pelos novos slides que devem ser gerados, para adicionar
        // ... após a última palavra caso não termine em pontuação e não seja
        // o último slide da quebra
        newSlides.forEach((slide: Word[], index) => {
          let lastWord = slide[slide.length - 1];
          let punctuation = /[!"#$%&'()*+,-./:;<=>?[\]^_`{|}~](?:\s+)?$/g;

          // adiciona ... se necessário
          if (
            lastWord.value.search(punctuation) < 0 &&
            index < newSlides.length - 1
          ) {
            let ellipsis: Word = {
              value: "...",
              hasBold: slide[slide.length - 1].hasBold,
              hasItalic: slide[slide.length - 1].hasItalic,
              hasUnderline: false,
              hasMarkdown: slide[slide.length - 1].hasMarkdown,
              hasLinebreak: false,
            };

            // se a última palavra for um espaço em branco, substitui. Caso contrário inclui
            if (lastWord.value === " ") {
              slide[slide.length - 1] = ellipsis;
            } else {
              slide.push(ellipsis);
            }
          }

          // insere o slide no array principal de slides
          slides.push(slide);
        });
      } else {
        // se não precisou quebrar, só insere o slide atual no array principal
        slides.push(wordsCollection);
      }
    });

    /**
     * Limita a quantidade de slides gerados na prévia para 30
     */
    if (this.options.isPreview && slides.length > 25) {
      slides.length = 25;
      slides.push([
        {
          value:
            "Gostou? A prévia é uma forma de ver como sua VSL ficará, e tem o limite de 25 slides.",
          hasBold: false,
          hasItalic: true,
          hasUnderline: false,
          hasMarkdown: false,
          hasLinebreak: false,
        },
        {
          value: "Clique em GERAR VSL para ver a versão completa.",
          hasBold: true,
          hasItalic: true,
          hasUnderline: false,
          hasMarkdown: false,
          hasLinebreak: false,
        },
      ]);
    }

    /**
     * Loop para geração dos slides
     */
    slides.forEach((currentSlide) => {
      // Adiciona um novo slide na apresentação
      let slide = pptx.addSlide();
      slide.background = { color: this.options.bgColor };

      if (this.options.bgImage.length) {
        slide.background = { data: this.options.bgImage }; // imagem em base64
      }

      slide.color = this.options.textColor; // Cor padrão do texto para o slide todo

      if (currentSlide.length) {
        // loop pelos fragmentos do texto
        const textFragments: any[] = currentSlide.map(
          (word: Word, index: number) => {
            // Converte 1ª eltra de cada palavra para maiúscula se necessário
            if (this.options.ucWords && word.value.length > 2) {
              word.value = capitalize(word.value);
            }

            // Converte a 1ª letra do slide para maiúscula se necessário
            if (this.options.ucFirst && index === 0) {
              word.value = capitalize(word.value);
            }

            // Adiciona "..." na última palavra do slide se necessário
            if (
              this.options.endWithEllipsis &&
              index === currentSlide.length - 1
            ) {
              let punctuation = /[!"#$%&'()*+,-./:;<=>?[\]^_`{|}~](?:\s+)?$/g;

              // adiciona ... apenas se a palavra não terminar com uma pontuação
              if (word.value.search(punctuation) < 0) {
                word.value = `${word.value}...`;
              }
            }

            return {
              text: word.value,
              options: {
                underline: { style: word.hasUnderline ? "sng" : "none" },
                color: word.hasMarkdown
                  ? this.options.markupTextColor
                  : this.options.textColor,
                highlight: word.hasMarkdown ? this.options.markupBgColor : "",
                bold: word.hasBold,
                italic: word.hasItalic,
                breakLine: word.hasLinebreak,
              },
            };
          }
        );

        if (this.options.isPreview || this.options.isFreeCredit) {
          slide.addImage({
            path: darkLogo,
            x: "78%",
            y: "87%",
            transparency: 60,
            w: 1.75,
            h: 0.5,
          });
        }

        // insere o texto no slide
        slide.addText(textFragments, {
          x: marginDefaults.x,
          y: marginDefaults.y,
          w: marginDefaults.w,
          h: marginDefaults.h,
          align: pptx.AlignH.center,
          fontSize: this.options.fontSize,
          fontFace: this.options.fontFamily,
          isTextBox: true,
          valign: this.options.verticalAlign.toLowerCase(),
          //shadow: {type: "outer",color: "696969",blur: 3,offset: 1.5,angle: 90,},
        });
      }
    });

    // Salva o arquivo
    if (this.options.isPreview) {
      return new Promise((resolve, reject) => {
        pptx
          .write({ outputType: "base64", compression: false })
          .then(async (data) => {
            resolve(data as string);
          })
          .catch((err) => {
            console.error(err);
          });
      });
    } else {
      pptx.writeFile({ fileName: `${this.options.vslTitle}.pptx` });
    }
  }

  /**
   * Valida se uma string deve ter uma formatação específica.
   *
   * @param currentState
   * @param word
   * @param tag
   * @returns
   */
  getWordFormating(
    currentState: boolean,
    word: string,
    tag: string
  ): WordStyleTag {
    // verifica se o estado atual da formatação é aberto
    if (currentState) {
      // se começa a string fechando a tag, retorna false
      if (word.startsWith(`</${tag}>`)) {
        return {
          current: false,
          next: false,
        };
      }

      // se fecha a tag em algum ponto da string, retorna true para a
      // atual e false para a próxima palavra
      if (word.includes(`</${tag}>`)) {
        return {
          current: true,
          next: false,
        };
      } else {
        // se não inclui a tag de fechamento, como sabemos que já estava
        // aberto antes, retorna true para essa e a próxima também
        return {
          current: true,
          next: true,
        };
      }
    } else if (word.includes(`<${tag}>`)) {
      return {
        current: true,
        next: !word.includes(`</${tag}>`),
      };
    }

    return {
      current: false,
      next: false,
    };
  }
}

/**
 * PPTXOptions Type
 */
type PPTXOptions = {
  vslText: any[];
  slideLayout: string;
  fontSize: number;
  textColor: string;
  bgColor: string;
  fontFamily: string;
  markupTextColor: string;
  markupBgColor: string;
  bgImage: string;
  verticalAlign: any;
  vslTitle?: string;
  margin: string;
  textPerSlide: string;
  ucWords: boolean;
  ucFirst: boolean;
  endWithEllipsis: boolean;
  isPreview: boolean;
  isFreeCredit: boolean;
};

/**
 * WordStyleTag Type
 */
type WordStyleTag = { current: boolean; next: boolean };

/**
 * Word Type
 */
type Word = {
  value: string;
  hasBold: boolean;
  hasItalic: boolean;
  hasUnderline: boolean;
  hasMarkdown: boolean;
  hasLinebreak: boolean;
};

export default PPTXGen;
