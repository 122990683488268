import { Image, useColorModeValue } from "@chakra-ui/react";
import darkLogo from "../assets/img/logo-dark.png";
import lightLogo from "../assets/img/logo-light.png";

export const Logo = () => {
  const logo = useColorModeValue(darkLogo, lightLogo);
  return (
    <Image src={logo} alt="Gerador de VSL" maxWidth={"40"} paddingStart="4" />
  );
};
