import { gql } from "@apollo/client";

export const GET_ACTIVE_TRANSACTION_AND_BALANCE = gql`
query GetActiveTransactionAndBalance {
  transactions(
    where: { status: { _eq: ACTIVE } }
    limit: 1
    order_by: { expires_on: asc_nulls_last }
  ) {
    id
    unlimited
    package {
      item_type
    }
  }
  users {
    transactions_aggregate(where: { status: { _eq: ACTIVE } }) {
      aggregate {
        sum {
          balance
        }
      }
    }
    transactions(
      where: { status: { _eq: ACTIVE }, package: { item_type: { _neq: FREE } } }
    ) {
      id
    }
  }
}
`;

export const GET_USER_BALANCE = gql`
query GetUserBalance {
  users {
    transactions_aggregate(where: {status: {_eq: ACTIVE}}) {
      aggregate {
        sum {
          balance
        }
      }
    }
  }
}
`;

export const GET_TRANSACTIONS = gql`
query GetTransactions {
  transactions {
    balance
    credits
    description
    expires_on
    id
    unlimited
    usages
    transaction_status {
      description
      value
    }
  }
}
`;

export const GET_HOME_DATA = gql`
query GetHomeData {
  users {
    transactions_aggregate(where: { status: { _eq: ACTIVE } }) {
      aggregate {
        sum {
          balance
        }
      }
    }
    vsls_aggregate(where: { status: { _eq: GENERATED } }) {
      aggregate {
        count
      }
    }
  }
  vsls(limit: 200, order_by: {created_at: desc}) {
    id
    title
    total_slides
    updated_at
    vsl_status {
      description
      value
    }
    transaction {
      description
    }
  }
}
`;

export const GET_VSL_BY_ID = gql`
query GetVslById($vsl_id: uuid!) {
  vsls_by_pk(id: $vsl_id) {
    editor_content
    layout
    font_size
    text_color
    bg_color
    font_family
    markup_text_color
    markup_bg_color
    bg_image
    vertical_align
    margin
    text_per_slide
    title
    uc_words
    uc_first
    end_with_ellipsis
    transaction {
      package {
        item_type
      }
    }
  }
}
`;