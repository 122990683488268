import { useQuery } from "@apollo/client";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  SimpleGrid,
  Spacer,
  Stack,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";

import {
  HiArrowDown,
  HiOutlineClipboardCopy,
  HiOutlineFolderAdd,
} from "react-icons/hi";
import { Link, NavLink } from "react-router-dom";
import FullScreenLoader from "../../components/FullScreenLoader";
import { useAuth } from "../../contexts/auth";
import { GET_HOME_DATA } from "../../graphql/queries";
import { Stat } from "./Stat";

type Vsl = {
  id: string;
  title: string;
  total_slides: number;
  status: string;
  updated_at: string;
  transaction: {
    description: string;
  };
  vsl_status: {
    value: string;
    description: string;
  };
};

const MyVslPage = () => {
  const { loading, error, data } = useQuery(GET_HOME_DATA, {
    fetchPolicy: "no-cache",
  });
  const { user } = useAuth();

  const headingSize = useBreakpointValue({ base: "xs", lg: "sm" });
  const boxShadow = useColorModeValue("sm", "sm-dark");

  if (loading) return <FullScreenLoader />;
  if (error) return <Text>Erro! {error.message}</Text>;
  return (
    <Stack spacing={{ base: "8", lg: "6" }} height="full">
      <Stack
        spacing="4"
        direction={{ base: "column", lg: "row" }}
        justify="space-between"
        align={{ base: "start", lg: "center" }}
      >
        <Stack spacing="1">
          <Heading size={headingSize} fontWeight="black">
            Minhas VSL
          </Heading>
          <Text color="muted">Um resumo de tudo que acontece na sua conta</Text>
        </Stack>
        <HStack spacing="3">
          <Button
            variant="primary"
            rightIcon={<HiOutlineFolderAdd />}
            as={NavLink}
            to="/new-vsl"
          >
            Nova VSL
          </Button>
        </HStack>
      </Stack>
      <Box as="section" py={{ base: "4", md: "8" }}>
        <SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: "5", md: "6" }}>
          <Stat
            key="1"
            label="Créditos Disponíveis"
            value={data.users[0].transactions_aggregate.aggregate.sum.balance}
          />
          <Stat
            key="2"
            label="VSLs Geradas"
            value={data.users[0].vsls_aggregate.aggregate.count}
          />
        </SimpleGrid>
      </Box>

      <Box>
        {data.vsls.map((vsl: Vsl) => (
          <Box
            bg="bg-surface"
            boxShadow={boxShadow}
            borderRadius="lg"
            p={4}
            mb={3}
            key={vsl.id}
          >
            <Flex>
              <Center>
                <Stack spacing={1}>
                  <Text>
                    {vsl.title}
                    <Badge
                      fontSize="xs"
                      ml="1"
                      px="2"
                      py="0"
                      colorScheme={
                        vsl.vsl_status.value === "GENERATED" ? "green" : "gray"
                      }
                    >
                      {vsl.vsl_status.description}
                    </Badge>
                  </Text>
                  <Text fontSize={"xs"} opacity="0.6">
                    {new Date(vsl.updated_at).toLocaleString()}
                    {" - "}
                    {vsl.total_slides}{" "}
                    {vsl.total_slides > 1 ? "slides" : "slide"}
                  </Text>
                </Stack>
              </Center>
              <Spacer />
              <Center>
                {vsl.vsl_status.value === "GENERATED" ? (
                  <Button variant="outline" size="xs" mr="1">
                    <HiArrowDown style={{ marginRight: "3px" }} />
                    <Link to={`/regenerate/${vsl.id}`}>Gerar PPTX</Link>
                  </Button>
                ) : (
                  ""
                )}
                <Button variant="outline" size="xs">
                  <HiOutlineClipboardCopy style={{ marginRight: "3px" }} />
                  <Link to={`/copy-vsl/${vsl.id}`}>Criar cópia</Link>
                </Button>
              </Center>
            </Flex>
          </Box>
        ))}
      </Box>
    </Stack>
  );
};

export default MyVslPage;
