import * as React from "react";
import {
  useColorMode,
  useColorModeValue,
  IconButtonProps,
  HStack,
  Icon,
  Button,
  Text,
} from "@chakra-ui/react";
import { HiMoon, HiSun } from "react-icons/hi";

type ColorModeSwitcherProps = Omit<IconButtonProps, "aria-label">;

export const ColorModeSwitcher: React.FC<ColorModeSwitcherProps> = (props) => {
  const { toggleColorMode } = useColorMode();
  const text = useColorModeValue("dark", "light");
  const label = useColorModeValue("Modo claro", "Modo escuro");
  const SwitchIcon: any = useColorModeValue(HiSun, HiMoon);

  return (
    <Button
      variant="ghost"
      justifyContent="start"
      label={label}
      onClick={toggleColorMode}
      aria-label={`Switch to ${text} mode`}
      {...props}
    >
      <HStack spacing="3">
        <Icon as={SwitchIcon} boxSize="6" color="subtle" />
        <Text>{label}</Text>
      </HStack>
    </Button>
  );
};
